import React, {useState} from 'react';
import {Navigate} from "react-router-dom";
import './ResetPassword.css'
import {userSendEmailResetPassword} from "../../services/user";

function SendEmailResetPassword() {
    const [email, setEmail] = useState('');
    const [flag, setFlag] = useState(false);

    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleSendEmailResetPassword= async (e) => {
        e.preventDefault();
        try {
            console.log({
                email
            });

            const userSendEmailResetPasswordResponse = await userSendEmailResetPassword({
                email
            });

            if (userSendEmailResetPasswordResponse.message) {
                setEmail('')
                setError(userSendEmailResetPasswordResponse.message.message);
                setTimeout(() => setError(''), 2000);
            }
            if (userSendEmailResetPasswordResponse.data) {
                console.log(userSendEmailResetPasswordResponse)
                setFlag(true)
            }
        } catch (error) {
            console.error('[handleLogin] ::', error);
            setEmail('')
            setError(error.response.data.message);
            setTimeout(() => setError(''), 2000);
        }
    };

    return (
        <div className="container vh-100 d-flex flex-column">
            {error && <div className="error-message">{error}</div>}
            {flag && <Navigate to="/login"/>}
            <div className="row justify-content-center align-items-center flex-grow-1 ">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">Send Email Reset Password</div>
                        <div className="card-body">
                            <form onSubmit={handleSendEmailResetPassword}>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email:</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SendEmailResetPassword;
