// LoginComponent.js
import React, {useState} from 'react';
import {login, storeTokensInLocalStorage} from "../../services/auth";
import {Navigate} from "react-router-dom";
import './Login.css'

function LoginComponent({handleSettingsClick}) {
    let agent = navigator.userAgent;
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [flag, setFlag] = useState(false);
    const [flagResetPassword, setFlagResetPassword] = useState(false);

    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleResetPassword = async (e) => {
        e.preventDefault();
        handleSettingsClick()
        setFlagResetPassword(true)
    }

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            console.log({
                email,
                password,
                agent
            });

            const userLogin = await login({
                email,
                password,
                agent:agent
            });

            if (userLogin.message) {
                setPassword('')
                setError(userLogin.message.message);
                setTimeout(() => setError(''), 2000);
            }
            if (userLogin.data) {
                console.log('[Login] ::',userLogin)
                storeTokensInLocalStorage(userLogin.data.accessToken, userLogin.data.refreshToken)
                handleSettingsClick()
                setFlag(true)
            }
        } catch (error) {
            setError(error.response.data.message);
            setTimeout(() => setError(''), 2000);
        }
    };

    return (
        <div className="container vh-100 d-flex flex-column">
            {error && <div className="error-message">{error}</div>}
            {flagResetPassword && <Navigate to="/send-email-reset-password"/>}
            {flag && <Navigate to="/"/>}
            <div className="row justify-content-center align-items-center flex-grow-1 ">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">Login</div>
                        <div className="card-body">
                            <form onSubmit={handleLogin}>
                                <div className="mb-3">
                                    <label htmlFor="email" className="form-label">Email:</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="password" className="form-label">Password:</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <a  onClick={(e) => handleResetPassword(e)} style={{textDecoration:'none'}}> Reset Password</a>
                                </div>
                                <button type="submit" className="btn btn-primary">Login</button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LoginComponent;
