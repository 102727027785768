import {useLocation} from "react-router-dom";
import React from "react";


function LogInformation() {
    const location = useLocation();
    console.log("Request Refresh ", location);
    return (
        <div>
            <h2>Request Refresh</h2>
            <p>{JSON.stringify(location)}</p>
        </div>
    );
}

export default LogInformation;
