import axiosInstance from "./axiosSetup";
import { jwtDecode } from "jwt-decode";
export function storeTokensInLocalStorage(access_token, refresh_token) {
    localStorage.setItem('access_token', access_token);
    localStorage.setItem('refresh_token', refresh_token);
    const decoded = jwtDecode(access_token);
    localStorage.setItem('user_info', JSON.stringify(decoded.data));
}

export function jwtDecodeToken(token) {
    return jwtDecode(token);
}

export function isTokenExpired(exp){
    return new Date(exp * 1000) > new Date()
}

export function getAccessTokenFromLocalStorage() {
    return localStorage.getItem('access_token');
}

export function getUserInfoFromLocalStorage() {
    return localStorage.getItem('user_info');
}

export function getRefreshTokenFromLocalStorage() {
    return localStorage.getItem('refresh_token');
}
export function removeLocalStorage() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user_info');
}

export const login = async ({ email, password,agent}) => {
    try {
        const response = await axiosInstance.post('/auth/login', {data:{
                email,
                password,
                device:agent
            }}, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;

    } catch (error) {
        console.error('[login] ::', error);
        return { message: error.response.data };
    }
};

export const refreshTokenServer = async ({ refreshToken }) => {
    try {
        const response = await axiosInstance.post('/auth/refreshToken', {data:{
                refreshToken
            }}, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;

    } catch (error) {
        console.error('[refreshToken] ::', error);
        return { message: error.response.data };
    }
};
