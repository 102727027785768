import React, {useState} from 'react';
import {Navigate,useLocation} from "react-router-dom";
import './ResetPassword.css'
import {userResetPassword} from "../../services/user";

function ResetPassword() {
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [flag, setFlag] = useState(false);

    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            console.log({
                new_password:password,
                confirm_new_password:confirmPassword,
                token
            });

            const resetPasswordResponse = await userResetPassword({
                new_password:password,
                confirm_new_password:confirmPassword,
                token
            });

            if (resetPasswordResponse.message) {
                setPassword('')
                setConfirmPassword('')
                setError(resetPasswordResponse.message.message);
                setTimeout(() => setError(''), 2000);
            }
            if (resetPasswordResponse.data) {
                setFlag(true)
            }
        } catch (error) {
            console.error('[handleLogin] ::', error);
            setPassword('')
            setConfirmPassword('')
            setError(error.response.data.message);
            setTimeout(() => setError(''), 2000);
        }
    };

    return (
        <div className="container vh-100 d-flex flex-column">
            {error && <div className="error-message">{error}</div>}
            {flag && <Navigate to="/login"/>}
            <div className="row justify-content-center align-items-center flex-grow-1 ">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header">Reset Password</div>
                        <div className="card-body">
                            <form onSubmit={handleLogin}>
                                <div className="mb-3">
                                    <label htmlFor="new_password" className="form-label">New Password:</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="new_password"
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <div className="mb-3">
                                    <label htmlFor="confirm_new_password" className="form-label">Confirm New Password:</label>
                                    <input
                                        type="password"
                                        className="form-control"
                                        id="confirm_new_password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                        required
                                    />
                                </div>
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
