const NotFound = () => {
    return (
        <div
            style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                textAlign: 'center',
                backgroundColor: '#343C6A',
                color: '#FFFFFF',
                padding: '20px',
                borderRadius: '10px',
                boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)'
            }}
        >
            <h1 style={{fontSize: '15rem', margin: '0'}}>404</h1>
            <p style={{fontSize: '5rem', margin: '0'}}>Not Found</p>
        </div>
    );
};

export default NotFound;
