import React from 'react';
import './Switch.css';

const Switch = ({isOn, handleToggle, flagSwitch, marginRight = 50}) => {
    return (
        <>
            <input
                checked={isOn}
                onChange={handleToggle}
                className="react-switch-checkbox"
                id={`react-switch-new-${flagSwitch}`}
                type="checkbox"
            />
            <label
                style={{background: isOn && '#34C759', marginRight: `${marginRight}px`}}
                className="react-switch-label"
                htmlFor={`react-switch-new-${flagSwitch}`}
            >
                <span className={`react-switch-button`}/>
            </label>
        </>
    );
};

export default Switch;
