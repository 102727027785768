import React from 'react';
import {Area, AreaChart, CartesianGrid, linearGradient, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';

const CustomAreaChart = ({data, gradientColor, strokeColor, dataKeyX, dataKeyY, keyColor, keyColorUrl}) => {
    return (
        <ResponsiveContainer width="100%" height="100%">
            <AreaChart
                data={data}
                margin={{top: 0, right: 0, left: 0, bottom: 0}}
            >
                <defs>
                    <linearGradient id={keyColor} x1="0" y1="0" x2="0" y2="1">
                        <stop offset="10%" stopColor={gradientColor} stopOpacity={0.8}/>
                        <stop offset="95%" stopColor={gradientColor} stopOpacity={0.1}/>
                    </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" vertical={false}/>
                <Area type="monotone" dataKey={dataKeyY} stroke={strokeColor} fillOpacity={1} fill={keyColorUrl}
                      strokeWidth={4}/>
                <XAxis dataKey={dataKeyX} axisLine={{stroke: 'none'}} tickLine={false} tick={{fill: '#718EBF'}}
                       tickMargin={12}/>
                <YAxis axisLine={{stroke: 'none'}} tickLine={false} tick={{fill: '#718EBF'}} tickMargin={15}
                       tickCount={4}/>
                <Tooltip/>
            </AreaChart>
        </ResponsiveContainer>
    );
}

export default CustomAreaChart;
