import React, {useEffect, useState} from 'react';
import {Link, Navigate, Outlet, useLocation} from 'react-router-dom';
import './Dashboard.css'

const Dashboard = ({handleActLink,showSettings}) => {
    const location = useLocation();

    const currentPath = location.pathname;
    const [activeLink, setActiveLink] = useState(currentPath !== '/' ? currentPath.replace('/setting', '') : '/dashboard/proposal/sending-stats');

    useEffect(() => {
        handleActLink(activeLink)
    }, []);
    const handleActiveLink = (link) => {
        setActiveLink(link);
        handleActLink(link)
    }

    return (
        <div className="container-fluid p-4">
            { showSettings && <Navigate to="/login" />}
            <div
                style={{fontWeight: 'bold', fontSize: '36px', color: '#343C6A', marginBottom: '10px'}}>Dashboard
            </div>
            <div
                className='custom-nav-dashboard col-12 col-xl-9 col-lg-9 col-md-11 col-sm-11 d-flex align-items-center'>
                <span style={{width: '10px'}}></span>
                <Link to="/dashboard/proposal/sending-stats"
                      style={{
                          textDecoration: 'none',
                          color: activeLink === '/dashboard/proposal/sending-stats' ? '#4273F4' : '#718EBF'
                      }}
                      onClick={() => handleActiveLink('/dashboard/proposal/sending-stats')}>Proposal Sending
                    Stats</Link>
                <span style={{width: '60px'}}></span>
                <Link to="/dashboard/proposal"
                      style={{
                          textDecoration: 'none',
                          color: activeLink === '/dashboard/proposal' ? '#4273F4' : '#718EBF'
                      }}
                      onClick={() => handleActiveLink('/dashboard/proposal')}>Proposal</Link>
                <span style={{width: '60px'}}></span>
                <Link to="/dashboard/invitations"
                      style={{
                          textDecoration: 'none',
                          color: activeLink === '/dashboard/invitations' ? '#4273F4' : '#718EBF'
                      }}
                      onClick={() => handleActiveLink('/dashboard/invitations')}>Invitations</Link>
            </div>
            <div>
                <div className='col-11 col-xl-8 col-lg-6 col-md-11 col-sm-11' style={{
                    position: 'absolute',
                    height: '1px',
                    top: '219px',
                    left: '24px',
                    background: '#EBEEF2'
                }}></div>
                {activeLink === '/dashboard/proposal/sending-stats' && (
                    <div style={{
                        position: 'absolute',
                        width: '190px',
                        height: '3px',
                        top: '217px',
                        left: '24px',
                        borderRadius: '10px  10px 0px 0px',
                        background: '#4273F4'
                    }}></div>
                )}
                {activeLink === '/dashboard/proposal' && (
                    <div style={{
                        position: 'absolute',
                        width: '90px',
                        height: '3px',
                        top: '217px',
                        left: '250px',
                        borderRadius: '10px  10px 0px 0px',
                        background: '#4273F4'
                    }}></div>
                )}
                {activeLink === '/dashboard/invitations' && (
                    <div style={{
                        position: 'absolute',
                        width: '104px',
                        height: '3px',
                        top: '217px',
                        left: '370px',
                        borderRadius: '10px  10px 0px 0px',
                        background: '#4273F4'
                    }}></div>
                )}
            </div>
            <Outlet/>

        </div>
    );
};

export default Dashboard;

