import axios from 'axios';
import {
    getAccessTokenFromLocalStorage,
    getRefreshTokenFromLocalStorage,
    isTokenExpired,
    jwtDecodeToken,
    refreshTokenServer, removeLocalStorage, storeTokensInLocalStorage
} from "./auth";

const axiosInstance = axios.create({
    baseURL: 'http://localhost:3001/api/v1',
});


axiosInstance.interceptors.request.use(async config => {
    const accessToken = getAccessTokenFromLocalStorage();
    if (accessToken) {
        const jwtDecodeTokenExp = jwtDecodeToken(accessToken);
        if (jwtDecodeTokenExp && isTokenExpired(jwtDecodeTokenExp.exp)) {
            config.headers['U-AuthToken'] = accessToken;
        } else {
            const refreshToken = getRefreshTokenFromLocalStorage();
            if (refreshToken ) {
                try {
                    const newAccessToken = await refreshTokenServer({refreshToken});
                    storeTokensInLocalStorage(newAccessToken.data.accessToken,newAccessToken.data.refreshToken)
                    config.headers['U-AuthToken'] = newAccessToken.data.accessToken;
                } catch (error) {
                    removeLocalStorage()
                    console.error("Error refreshing access token server:", error);

                }
            } else {
                removeLocalStorage()
                console.error("Not refreshToken :");
            }
        }
    }
    else {
        removeLocalStorage()
        console.error("Not AccessToken:");
    }
    return config;
}, error => {
    return Promise.reject(error);
});

export default axiosInstance;
