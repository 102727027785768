import React, { useState } from 'react';
import './Pagination.css'; // Assuming CSS file is named Pagination.css

const Pagination = ({ totalPages,handlePreviousTest }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const handlePrevious = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            handlePreviousTest(currentPage - 1)
        }
    };

    const handleNext = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
            handlePreviousTest(currentPage + 1)
        }
    };

    const handlePageClick = (page) => {
        setCurrentPage(page);
        handlePreviousTest(page)
    };

    const renderPaginationItems = () => {
        const paginationItems = [];

        if (totalPages <= 7) {
            for (let i = 1; i <= totalPages; i++) {
                paginationItems.push(
                    <span
                        key={i}
                        onClick={() => handlePageClick(i)}
                        className={i === currentPage ? 'active' : ''}
                    >
                        {i}
                    </span>
                );
            }
        } else {
            if (currentPage <= 4) {
                for (let i = 1; i <= 5; i++) {
                    paginationItems.push(
                        <span
                            key={i}
                            onClick={() => handlePageClick(i)}
                            className={i === currentPage ? 'active' : ''}
                        >
                            {i}
                        </span>
                    );
                }
                paginationItems.push(<span key="ellipsis-start">...</span>);
                paginationItems.push(
                    <span key={totalPages} onClick={() => handlePageClick(totalPages)}>
                        {totalPages}
                    </span>
                );
            } else if (currentPage >= totalPages - 3) {
                paginationItems.push(
                    <span key={1} onClick={() => handlePageClick(1)}>
                        1
                    </span>
                );
                paginationItems.push(<span key="ellipsis-start">...</span>);
                for (let i = totalPages - 4; i <= totalPages; i++) {
                    paginationItems.push(
                        <span
                            key={i}
                            onClick={() => handlePageClick(i)}
                            className={i === currentPage ? 'active' : ''}
                        >
                            {i}
                        </span>
                    );
                }
            } else {
                paginationItems.push(
                    <span key={1} onClick={() => handlePageClick(1)}>
                        1
                    </span>
                );
                paginationItems.push(<span key="ellipsis-start">...</span>);
                for (let i = currentPage - 1; i <= currentPage + 1; i++) {
                    paginationItems.push(
                        <span
                            key={i}
                            onClick={() => handlePageClick(i)}
                            className={i === currentPage ? 'active' : ''}
                        >
                            {i}
                        </span>
                    );
                }
                paginationItems.push(<span key="ellipsis-end">...</span>);
                paginationItems.push(
                    <span key={totalPages} onClick={() => handlePageClick(totalPages)}>
                        {totalPages}
                    </span>
                );
            }
        }

        return paginationItems;
    };

    return (
        <div className="pagination">
            <div className={`pagination-button-start ${currentPage === 1 ? 'disabled' : ''}`} onClick={currentPage === 1 ? null : handlePrevious}>
                {"<"} Previous
            </div>
            <div className="pagination-items">
                {renderPaginationItems()}
            </div>
            <div className={`pagination-button-end ${currentPage === totalPages ? 'disabled' : ''}`} onClick={currentPage === totalPages ? null : handleNext}>
                Next {">"}
            </div>
        </div>
    );
};

export default Pagination;
