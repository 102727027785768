import axiosInstance from './axiosSetup';

export const getUserById = async ({ userId }) => {
    try {
        console.log(userId)
        const response = await axiosInstance.get(`/user/${userId}`);

        console.log('response',response)
        if (response.data.message) {
            return { message: response.data.message };
        } else {
            return response.data;
        }

    } catch (error) {
        console.error('[getUserById] ::', error);
        return { message: error.response.data };
    }
}

export const getListUserByCompanyId = async ({ companyId }) => {
    try {
        const response = await axiosInstance.get(`/company/${companyId}/list-user`);

        return response.data;

    } catch (error) {
        console.error('[getListUserByCompanyId] ::', error);
        return { message: error.response.data };
    }
}

export const userInvite = async ({ email, role, companyId }) => {
    try {

        const response = await axiosInstance.post('/user/invite', {
            email,
            role,
            companyId
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;

    } catch (error) {
        console.error('[userInvite] axios ::', error);
        return { message: error.response.data };
    }
};

export const userResetPassword = async ({ new_password, confirm_new_password, token }) => {
    try {

        const response = await axiosInstance.post('/user/reset-password', {
            new_password,
            confirm_new_password,
            token
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;

    } catch (error) {
        console.error('[userResetPassword] axios ::', error);
        return { message: error.response.data };
    }
};

export const userSendEmailResetPassword = async ({ email }) => {
    try {

        const response = await axiosInstance.post('/user/email/reset-password', {
            email
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;

    } catch (error) {
        console.error('[userSendEmailResetPassword] axios ::', error);
        return { message: error.response.data };
    }
};

export const updateRole = async ({ userId, role }) => {
    try {
        const response = await axiosInstance.put(`/user/${userId}/role`, {
            role
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;

    } catch (error) {
        console.error('[updateRole] ::', error);
        return { message: error.response.data };
    }
};

export const deleteUser = async ({ userId }) => {
    try {
        const response = await axiosInstance.delete(`/user/${userId}`,  {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;

    } catch (error) {
        console.error('[deleteUser] ::', error);
        return { message: error.response.data };
    }
};
