import './Settings.css'
import React, {useEffect, useState} from "react";
import Switch from "./switch/Switch";
import {Link, Navigate, useLocation} from "react-router-dom";
import {deleteUser, getListUserByCompanyId, userInvite} from "../../services/user";
import {getUserInfoFromLocalStorage} from "../../services/auth";

const Setting = ({}) => {
    const location = useLocation();
    const [userRoleSetting, setUserRoleSetting] = useState(JSON.parse(getUserInfoFromLocalStorage()));

    const currentPath = location.pathname.replace('/setting', '');

    const columns = ['E-mail', 'Role', ''];
    const order_status = ['Member', 'Admin']
    const [activeLink, setActiveLink] = useState('Preferences');
    const [valueInvitation, setValueInvitation] = useState(false);
    const [valueReply, setValueReply] = useState(false);
    const [valueConnect, setValueConnect] = useState(false);
    const [valueConnectAlert, setValueConnectAlert] = useState(false);
    const [valueTopUpAlert, setValueTopUpAlert] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const [isUserInviteSuccess, setIsUserInviteSuccess] = useState(false);

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [selectedOrdersStatus, setSelectedOrdersStatus] = useState('Admin');
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(false);

    const [listUser, setListUser] = useState(null);

    async function fetchUserData(flag) {
        try {
            console.log('FLAD',flag)
            const dataResponse = await getListUserByCompanyId({companyId: 'd67fg37uygrufgr'});
            console.log(dataResponse)
            setListUser(dataResponse ? dataResponse : {data: []});
        } catch (error) {
            console.error('[fetchData] ::', error);
        }
    }

    useEffect(() => {
        if(isUserInviteSuccess){
            fetchUserData('isUserInviteSuccess');
            setIsUserInviteSuccess(false)
        }
    }, [isUserInviteSuccess]);

    useEffect(() => {
        fetchUserData(userRoleSetting.company);
    }, []);

    const toggleReply = () => setValueReply(!valueReply);
    const toggleInvitation = () => setValueInvitation(!valueInvitation);
    const toggleConnect = () => setValueConnect(!valueConnect);
    const toggleConnectAlert = () => setValueConnectAlert(!valueConnectAlert);
    const toggleTopUpAlert = () => setValueTopUpAlert(!valueTopUpAlert);


    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    const handleStatusSelection = (orderStatus) => {
        setSelectedOrdersStatus(orderStatus);
        setIsDropdownOpen(false);
    };

    const handleChange = (e) => {
        const {value} = e.target;
        setEmail(value);
        setIsValid(false);
    };

    const handleUserInvite = async () => {
        if (!email) {
            setIsValid(true);
        }
        try {
            if (email && selectedOrdersStatus) {
                const userInviteResponse = await userInvite({
                    email,
                    role: selectedOrdersStatus,
                    companyId: 'd67fg37uygrufgr'
                });
                setIsUserInviteSuccess(true);
                setEmail('');
                console.log('[userInvite Response] ::', userInviteResponse)
                if (userInviteResponse.message) {
                    setError(userInviteResponse.message.message); // Зберігаємо повідомлення про помилку у стані
                    setTimeout(() => setError(''), 2000);
                } else {
                    setSuccessMessage('Send Successful Invite ');
                    setTimeout(() => setSuccessMessage(''), 2000);
                }

            }
        } catch (error) {
            console.error('[userInvite] ::', error);
            console.error('[userInvite message] ::', error.response.data.message);
            setError(error.response.data.message); // Зберігаємо повідомлення про помилку у стані
            setTimeout(() => setError(''), 2000);
        }

    };

    const handleRemoveUser = async (userId) => {
        try {
            const userDeleteResponse = await deleteUser({
                userId
            });
            setIsUserInviteSuccess(true);
            console.log('[deleteUser Response] ::', userDeleteResponse)
            if (userDeleteResponse.message) {
                setError(userDeleteResponse.message.message);
                setTimeout(() => setError(''), 2000);
            } else {
                setSuccessMessage('Delete User Successful ');
                setTimeout(() => setSuccessMessage(''), 2000);
            }
        } catch (error) {
            console.error('[deleteUser] ::', error);
            console.error('[deleteUser message] ::', error.response.data.message);
            setError(error.response.data.message);
            setTimeout(() => setError(''), 2000);
        }
    }

    const renderTableData = () => {
        const data = listUser?listUser.data:[]
        return data.map((item) => (
            <tr key={item.id}>
                <td className='pt-2 pb-2 p-0'>
                    <div style={{
                        fontWeight: '500',
                        color: '#545454',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <span>{item.email}</span>
                    </div>
                </td>
                <td className='pt-2 pb-2 p-0'>

                    <div style={{
                        fontWeight: '500',
                        color: '#232323',
                        fontSize: '14px',
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        <span>{item.role}</span>
                    </div>
                </td>
                <td className='pt-2 pb-2 p-0 d-flex justify-content-end'>
                    <button
                        className="btn p-0"
                        onClick={() => handleRemoveUser(item.id)}
                        style={{
                            width: '100px',
                            fontSize: '12px',
                            height: '26px',
                            backgroundColor: '#FE5C73',
                            color: 'white'
                        }}>Remove
                    </button>
                </td>
            </tr>
        ));
    };

    return (
        <div style={{display: "flex", justifyContent: 'center', alignItems: 'center'}}>
            <div className="overlay" style={{display: true ? 'block' : 'none'}}></div>
            {userRoleSetting && userRoleSetting.role==='Member' && <Navigate to="/dashboard/proposal/sending-stats"/>}
            {error && <div className="error-message">{error}</div>}
            {successMessage && <div className="success-message">{successMessage}</div>}
            <div className="setting">
                <div className="container m-0 p-4">
                    <div className="row m-0 p-0">
                        <div
                            className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 p-0 d-flex justify-content-between">
                            <div style={{fontWeight: 'bold', color: 'black', fontSize: '24px'}}>
                                Team Settings
                            </div>
                            <div style={{fontWeight: 'bold', color: 'black', fontSize: '24px'}}>
                                <Link to={currentPath}
                                      style={{textDecoration: 'none', color: 'black'}}
                                >
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.46409 15.5354L15.5352 8.46436" stroke="black" stroke-width="1.5"
                                              stroke-linecap="round"/>
                                        <path d="M8.46409 8.46458L15.5352 15.5356" stroke="black" stroke-width="1.5"
                                              stroke-linecap="round"/>
                                    </svg>
                                </Link>
                            </div>
                        </div>
                        <div className="col-12 col-xl-8 col-lg-7 col-md-12 col-sm-12 mt-3 p-0">
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill="currentColor"
                                 className="bi bi-person-circle" viewBox="0 0 16 16">
                                <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                                <path fill-rule="evenodd"
                                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                            </svg>
                            <span className='m-3' style={{fontWeight: 'bold', color: 'black', fontSize: '18px'}}>SPLIT Development</span>
                        </div>
                    </div>

                    <div
                        className='custom-nav-dashboard col-11 col-xl-9 col-lg-9 col-md-11 col-sm-11 mb-2 mt-4 d-flex align-items-center'>
                            <span style={{
                                marginLeft: '10px',
                                textDecoration: 'none',
                                color: activeLink === 'Preferences' ? '#4273F4' : '#718EBF'
                            }} onClick={() => setActiveLink('Preferences')}>Preferences</span>
                        <span style={{width: '60px'}}></span>
                        <span style={{
                            textDecoration: 'none',
                            color: activeLink === 'Billing' ? '#4273F4' : '#718EBF'
                        }} onClick={() => setActiveLink('Billing')}>Billing</span>
                        <span style={{width: '60px'}}></span>
                        <span style={{
                            textDecoration: 'none',
                            color: activeLink === 'Members' ? '#4273F4' : '#718EBF'
                        }} onClick={() => setActiveLink('Members')}>Members</span>
                    </div>

                    <div>
                        <div className='col-11 col-xl-11 col-lg-11 col-md-11 col-sm-11 p-0' style={{
                            position: 'absolute',
                            height: '1px',
                            background: '#EBEEF2',
                            marginTop: "1.5px",
                        }}></div>
                        {activeLink === 'Preferences' && (
                            <div style={{
                                position: 'absolute',
                                width: '115px',
                                height: '3px',
                                left: '25px',
                                borderRadius: '10px  10px 0px 0px',
                                background: '#4273F4'
                            }}></div>
                        )}
                        {activeLink === 'Billing' && (
                            <div style={{
                                position: 'absolute',
                                width: '70px',
                                height: '3px',
                                left: '170px',
                                borderRadius: '10px  10px 0px 0px',
                                background: '#4273F4'
                            }}></div>
                        )}
                        {activeLink === 'Members' && (
                            <div style={{
                                position: 'absolute',
                                width: '100px',
                                height: '3px',
                                left: '270px',
                                borderRadius: '10px  10px 0px 0px',
                                background: '#4273F4'
                            }}></div>
                        )}
                    </div>

                    {activeLink === 'Preferences' && (
                        <div>
                            <div className='mt-4 p-0 '
                                 style={{fontWeight: 'bold', color: 'black', fontSize: '20px'}}>Configuration
                            </div>
                            <div className='row mt-2 d-flex justify-content-center align-items-center '>
                                <div className="col-8 col-xl-10 col-lg-10 col-md-10 col-sm-10">
                                    <div style={{
                                        fontWeight: 'bold',
                                        color: '#232323',
                                        fontSize: '16px',
                                        marginBottom: '8px'
                                    }}>Lead reply notifications
                                    </div>
                                    <div style={{color: '#232323', fontSize: '12px'}}>
                                        Get a notification every time a proposal that is sent from your ream gets a
                                        new reply.
                                    </div>
                                </div>
                                <div
                                    className="col-4 col-xl-2 col-lg-2 col-md-2 col-sm-2 p-0  d-flex justify-content-end">
                                    <Switch
                                        isOn={valueReply}
                                        handleToggle={toggleReply}
                                        flagSwitch={'reply'}
                                        marginRight={20}
                                    />
                                </div>
                            </div>
                            <div className='row mt-3 d-flex align-items-center'
                                 style={{opacity: valueReply ? '1' : '0.5'}}>
                                <div className="col-7 col-xl-7 col-lg-7 col-md-7 col-sm-7">
                                    <label htmlFor="inviteMember" className="input-with-icon"
                                           style={{display: 'block', width: '100%'}}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_23_2617)">
                                                <path
                                                    d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                                                    fill="url(#paint0_linear_23_2617)"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M5.43104 11.8734C8.92928 10.3493 11.262 9.34452 12.4292 8.85905C15.7617 7.47294 16.4542 7.23216 16.9055 7.22421C17.0048 7.22246 17.2267 7.24706 17.3705 7.36372C17.4919 7.46223 17.5253 7.5953 17.5413 7.6887C17.5573 7.78209 17.5772 7.99485 17.5614 8.1611C17.3808 10.0586 16.5994 14.6633 16.2018 16.7885C16.0336 17.6877 15.7024 17.9892 15.3817 18.0188C14.6849 18.0829 14.1557 17.5582 13.4808 17.1158C12.4246 16.4235 11.8279 15.9925 10.8028 15.3169C9.61798 14.5362 10.386 14.107 11.0612 13.4058C11.2379 13.2222 14.3083 10.4295 14.3677 10.1761C14.3752 10.1444 14.3821 10.0263 14.3119 9.96397C14.2417 9.9016 14.1382 9.92293 14.0635 9.93989C13.9575 9.96393 12.2703 11.0791 9.00179 13.2855C8.52287 13.6143 8.08909 13.7745 7.70043 13.7662C7.27197 13.7569 6.44778 13.5239 5.83508 13.3247C5.08358 13.0804 4.4863 12.9513 4.53831 12.5364C4.5654 12.3203 4.86298 12.0993 5.43104 11.8734Z"
                                                      fill="white"/>
                                            </g>
                                            <defs>
                                                <linearGradient id="paint0_linear_23_2617" x1="12" y1="0" x2="12"
                                                                y2="23.822" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#2AABEE"/>
                                                    <stop offset="1" stop-color="#229ED9"/>
                                                </linearGradient>
                                                <clipPath id="clip0_23_2617">
                                                    <rect width="24" height="24" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            type="text"
                                            id="inviteMember"
                                            className="form-control"
                                            placeholder="Telegram Channel"
                                            style={{width: 'calc(100%)', paddingLeft: '40px'}}
                                        />
                                    </label>
                                </div>
                                <div className="col-2 col-xl-2 col-lg-2 col-md-2 col-sm-2">
                                    <div style={{
                                        width: "24px",
                                        height: "25px",
                                        backgroundColor: "rgba(33, 207, 123, 0.25)",
                                        position: 'relative',
                                        borderRadius: '5px'
                                    }}>
                                        <svg width="14" height="14" viewBox="0 0 10 9" fill="#21CF7B"
                                             xmlns="http://www.w3.org/2000/svg" style={{
                                            position: 'absolute',
                                            top: "50%",
                                            left: '50%',
                                            transform: "translate(-50%, -50%)",
                                            opacity: '1'
                                        }}> >
                                            <path
                                                d="M3.57945 8.0801C3.37945 8.0801 3.18945 8.0001 3.04945 7.8601L0.219453 5.0301C-0.0705469 4.7401 -0.0705469 4.2601 0.219453 3.9701C0.509453 3.6801 0.989453 3.6801 1.27945 3.9701L3.57945 6.2701L8.71945 1.1301C9.00945 0.840098 9.48945 0.840098 9.77945 1.1301C10.0695 1.4201 10.0695 1.9001 9.77945 2.1901L4.10945 7.8601C3.96945 8.0001 3.77945 8.0801 3.57945 8.0801Z"
                                                fill="#21CF7B"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 d-flex align-items-center'
                                 style={{opacity: valueReply ? '1' : '0.5'}}>
                                <div className="col-7 col-xl-7 col-lg-7 col-md-7 col-sm-7">
                                    <label htmlFor="inviteMember" className="input-with-icon"
                                           style={{display: 'block', width: '100%'}}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 0.25C18.4893 0.25 23.75 5.51065 23.75 12C23.75 18.4893 18.4893 23.75 12 23.75C5.51065 23.75 0.25 18.4893 0.25 12C0.25 5.51065 5.51065 0.25 12 0.25Z"
                                                fill="#FDFDFD" stroke="#E2E2E2" stroke-width="0.5"/>
                                            <path
                                                d="M8.53011 13.5863C8.53011 14.2838 7.96641 14.8475 7.26896 14.8475C6.57151 14.8475 6.00781 14.2838 6.00781 13.5863C6.00781 12.8889 6.57151 12.3252 7.26896 12.3252H8.53011V13.5863Z"
                                                fill="#E01E5A"/>
                                            <path
                                                d="M9.16406 13.5863C9.16406 12.8889 9.72776 12.3252 10.4252 12.3252C11.1227 12.3252 11.6864 12.8889 11.6864 13.5863V16.7392C11.6864 17.4367 11.1227 18.0004 10.4252 18.0004C9.72776 18.0004 9.16406 17.4367 9.16406 16.7392V13.5863Z"
                                                fill="#E01E5A"/>
                                            <path
                                                d="M10.4252 8.52229C9.72776 8.52229 9.16406 7.9586 9.16406 7.26115C9.16406 6.56369 9.72776 6 10.4252 6C11.1227 6 11.6864 6.56369 11.6864 7.26115V8.52229H10.4252Z"
                                                fill="#36C5F0"/>
                                            <path
                                                d="M10.4236 9.16211C11.121 9.16211 11.6847 9.7258 11.6847 10.4233C11.6847 11.1207 11.121 11.6844 10.4236 11.6844H7.26115C6.56369 11.6844 6 11.1207 6 10.4233C6 9.7258 6.56369 9.16211 7.26115 9.16211H10.4236Z"
                                                fill="#36C5F0"/>
                                            <path
                                                d="M15.4766 10.4233C15.4766 9.7258 16.0403 9.16211 16.7377 9.16211C17.4352 9.16211 17.9989 9.7258 17.9989 10.4233C17.9989 11.1207 17.4352 11.6844 16.7377 11.6844H15.4766V10.4233Z"
                                                fill="#2EB67D"/>
                                            <path
                                                d="M14.8465 10.4236C14.8465 11.121 14.2828 11.6847 13.5854 11.6847C12.8879 11.6847 12.3242 11.121 12.3242 10.4236V7.26115C12.3242 6.56369 12.8879 6 13.5854 6C14.2828 6 14.8465 6.56369 14.8465 7.26115V10.4236Z"
                                                fill="#2EB67D"/>
                                            <path
                                                d="M13.5854 15.4775C14.2828 15.4775 14.8465 16.0412 14.8465 16.7387C14.8465 17.4361 14.2828 17.9998 13.5854 17.9998C12.8879 17.9998 12.3242 17.4361 12.3242 16.7387V15.4775H13.5854Z"
                                                fill="#ECB22E"/>
                                            <path
                                                d="M13.5854 14.8475C12.8879 14.8475 12.3242 14.2838 12.3242 13.5863C12.3242 12.8889 12.8879 12.3252 13.5854 12.3252H16.7478C17.4452 12.3252 18.0089 12.8889 18.0089 13.5863C18.0089 14.2838 17.4452 14.8475 16.7478 14.8475H13.5854Z"
                                                fill="#ECB22E"/>
                                        </svg>
                                        <input
                                            type="text"
                                            id="inviteMember"
                                            className="form-control"
                                            placeholder="Slack Channel"
                                            style={{width: 'calc(100%)', paddingLeft: '40px'}}
                                        />
                                    </label>
                                </div>
                                <div className="col-2 col-xl-2 col-lg-2 col-md-2 col-sm-2">
                                    <div style={{
                                        width: "24px",
                                        height: "25px",
                                        backgroundColor: "rgba(33, 207, 123, 0.25)",
                                        position: 'relative',
                                        borderRadius: '5px'
                                    }}>
                                        <svg width="14" height="14" viewBox="0 0 10 9" fill="#21CF7B"
                                             xmlns="http://www.w3.org/2000/svg" style={{
                                            position: 'absolute',
                                            top: "50%",
                                            left: '50%',
                                            transform: "translate(-50%, -50%)",
                                            opacity: '1'
                                        }}> >
                                            <path
                                                d="M3.57945 8.0801C3.37945 8.0801 3.18945 8.0001 3.04945 7.8601L0.219453 5.0301C-0.0705469 4.7401 -0.0705469 4.2601 0.219453 3.9701C0.509453 3.6801 0.989453 3.6801 1.27945 3.9701L3.57945 6.2701L8.71945 1.1301C9.00945 0.840098 9.48945 0.840098 9.77945 1.1301C10.0695 1.4201 10.0695 1.9001 9.77945 2.1901L4.10945 7.8601C3.96945 8.0001 3.77945 8.0801 3.57945 8.0801Z"
                                                fill="#21CF7B"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-3 d-flex justify-content-center align-items-center '>
                                <div className="col-8 col-xl-10 col-lg-10 col-md-10 col-sm-10">
                                    <div style={{
                                        fontWeight: 'bold',
                                        color: '#232323',
                                        fontSize: '16px',
                                        marginBottom: '8px'
                                    }}>Client invitation notifications
                                    </div>
                                    <div style={{color: '#232323', fontSize: '12px'}}>
                                        Get a notification every time a prospect invites you to submit a proposal.
                                    </div>
                                </div>
                                <div
                                    className="col-4 col-xl-2 col-lg-2 col-md-2 col-sm-2 p-0 d-flex justify-content-end  ">
                                    <Switch
                                        isOn={valueInvitation}
                                        handleToggle={toggleInvitation}
                                        flagSwitch={'invitation'}
                                        marginRight={20}
                                    />
                                </div>
                            </div>

                            <div className='row mt-3 d-flex align-items-center'
                                 style={{opacity: valueInvitation ? '1' : '0.5'}}>
                                <div className="col-7 col-xl-7 col-lg-7 col-md-7 col-sm-7">
                                    <label htmlFor="inviteMember" className="input-with-icon"
                                           style={{display: 'block', width: '100%'}}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_23_2617)">
                                                <path
                                                    d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                                                    fill="url(#paint0_linear_23_2617)"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M5.43104 11.8734C8.92928 10.3493 11.262 9.34452 12.4292 8.85905C15.7617 7.47294 16.4542 7.23216 16.9055 7.22421C17.0048 7.22246 17.2267 7.24706 17.3705 7.36372C17.4919 7.46223 17.5253 7.5953 17.5413 7.6887C17.5573 7.78209 17.5772 7.99485 17.5614 8.1611C17.3808 10.0586 16.5994 14.6633 16.2018 16.7885C16.0336 17.6877 15.7024 17.9892 15.3817 18.0188C14.6849 18.0829 14.1557 17.5582 13.4808 17.1158C12.4246 16.4235 11.8279 15.9925 10.8028 15.3169C9.61798 14.5362 10.386 14.107 11.0612 13.4058C11.2379 13.2222 14.3083 10.4295 14.3677 10.1761C14.3752 10.1444 14.3821 10.0263 14.3119 9.96397C14.2417 9.9016 14.1382 9.92293 14.0635 9.93989C13.9575 9.96393 12.2703 11.0791 9.00179 13.2855C8.52287 13.6143 8.08909 13.7745 7.70043 13.7662C7.27197 13.7569 6.44778 13.5239 5.83508 13.3247C5.08358 13.0804 4.4863 12.9513 4.53831 12.5364C4.5654 12.3203 4.86298 12.0993 5.43104 11.8734Z"
                                                      fill="white"/>
                                            </g>
                                            <defs>
                                                <linearGradient id="paint0_linear_23_2617" x1="12" y1="0" x2="12"
                                                                y2="23.822" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#2AABEE"/>
                                                    <stop offset="1" stop-color="#229ED9"/>
                                                </linearGradient>
                                                <clipPath id="clip0_23_2617">
                                                    <rect width="24" height="24" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            type="text"
                                            id="inviteMember"
                                            className="form-control"
                                            placeholder="Telegram Channel"
                                            style={{width: 'calc(100%)', paddingLeft: '40px'}}
                                        />
                                    </label>
                                </div>
                                <div className="col-2 col-xl-2 col-lg-2 col-md-2 col-sm-2">
                                    <div style={{
                                        width: "24px",
                                        height: "25px",
                                        backgroundColor: "rgba(33, 207, 123, 0.25)",
                                        position: 'relative',
                                        borderRadius: '5px'
                                    }}>
                                        <svg width="14" height="14" viewBox="0 0 10 9" fill="#21CF7B"
                                             xmlns="http://www.w3.org/2000/svg" style={{
                                            position: 'absolute',
                                            top: "50%",
                                            left: '50%',
                                            transform: "translate(-50%, -50%)",
                                            opacity: '1'
                                        }}> >
                                            <path
                                                d="M3.57945 8.0801C3.37945 8.0801 3.18945 8.0001 3.04945 7.8601L0.219453 5.0301C-0.0705469 4.7401 -0.0705469 4.2601 0.219453 3.9701C0.509453 3.6801 0.989453 3.6801 1.27945 3.9701L3.57945 6.2701L8.71945 1.1301C9.00945 0.840098 9.48945 0.840098 9.77945 1.1301C10.0695 1.4201 10.0695 1.9001 9.77945 2.1901L4.10945 7.8601C3.96945 8.0001 3.77945 8.0801 3.57945 8.0801Z"
                                                fill="#21CF7B"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 d-flex align-items-center'
                                 style={{opacity: valueInvitation ? '1' : '0.5'}}>
                                <div className="col-7 col-xl-7 col-lg-7 col-md-7 col-sm-7">
                                    <label htmlFor="inviteMember" className="input-with-icon"
                                           style={{display: 'block', width: '100%'}}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 0.25C18.4893 0.25 23.75 5.51065 23.75 12C23.75 18.4893 18.4893 23.75 12 23.75C5.51065 23.75 0.25 18.4893 0.25 12C0.25 5.51065 5.51065 0.25 12 0.25Z"
                                                fill="#FDFDFD" stroke="#E2E2E2" stroke-width="0.5"/>
                                            <path
                                                d="M8.53011 13.5863C8.53011 14.2838 7.96641 14.8475 7.26896 14.8475C6.57151 14.8475 6.00781 14.2838 6.00781 13.5863C6.00781 12.8889 6.57151 12.3252 7.26896 12.3252H8.53011V13.5863Z"
                                                fill="#E01E5A"/>
                                            <path
                                                d="M9.16406 13.5863C9.16406 12.8889 9.72776 12.3252 10.4252 12.3252C11.1227 12.3252 11.6864 12.8889 11.6864 13.5863V16.7392C11.6864 17.4367 11.1227 18.0004 10.4252 18.0004C9.72776 18.0004 9.16406 17.4367 9.16406 16.7392V13.5863Z"
                                                fill="#E01E5A"/>
                                            <path
                                                d="M10.4252 8.52229C9.72776 8.52229 9.16406 7.9586 9.16406 7.26115C9.16406 6.56369 9.72776 6 10.4252 6C11.1227 6 11.6864 6.56369 11.6864 7.26115V8.52229H10.4252Z"
                                                fill="#36C5F0"/>
                                            <path
                                                d="M10.4236 9.16211C11.121 9.16211 11.6847 9.7258 11.6847 10.4233C11.6847 11.1207 11.121 11.6844 10.4236 11.6844H7.26115C6.56369 11.6844 6 11.1207 6 10.4233C6 9.7258 6.56369 9.16211 7.26115 9.16211H10.4236Z"
                                                fill="#36C5F0"/>
                                            <path
                                                d="M15.4766 10.4233C15.4766 9.7258 16.0403 9.16211 16.7377 9.16211C17.4352 9.16211 17.9989 9.7258 17.9989 10.4233C17.9989 11.1207 17.4352 11.6844 16.7377 11.6844H15.4766V10.4233Z"
                                                fill="#2EB67D"/>
                                            <path
                                                d="M14.8465 10.4236C14.8465 11.121 14.2828 11.6847 13.5854 11.6847C12.8879 11.6847 12.3242 11.121 12.3242 10.4236V7.26115C12.3242 6.56369 12.8879 6 13.5854 6C14.2828 6 14.8465 6.56369 14.8465 7.26115V10.4236Z"
                                                fill="#2EB67D"/>
                                            <path
                                                d="M13.5854 15.4775C14.2828 15.4775 14.8465 16.0412 14.8465 16.7387C14.8465 17.4361 14.2828 17.9998 13.5854 17.9998C12.8879 17.9998 12.3242 17.4361 12.3242 16.7387V15.4775H13.5854Z"
                                                fill="#ECB22E"/>
                                            <path
                                                d="M13.5854 14.8475C12.8879 14.8475 12.3242 14.2838 12.3242 13.5863C12.3242 12.8889 12.8879 12.3252 13.5854 12.3252H16.7478C17.4452 12.3252 18.0089 12.8889 18.0089 13.5863C18.0089 14.2838 17.4452 14.8475 16.7478 14.8475H13.5854Z"
                                                fill="#ECB22E"/>
                                        </svg>
                                        <input
                                            type="text"
                                            id="inviteMember"
                                            className="form-control"
                                            placeholder="Slack Channel"
                                            style={{width: 'calc(100%)', paddingLeft: '40px'}}
                                        />
                                    </label>
                                </div>
                                <div className="col-2 col-xl-2 col-lg-2 col-md-2 col-sm-2">
                                    <div style={{
                                        width: "24px",
                                        height: "25px",
                                        backgroundColor: "rgba(33, 207, 123, 0.25)",
                                        position: 'relative',
                                        borderRadius: '5px'
                                    }}>
                                        <svg width="14" height="14" viewBox="0 0 10 9" fill="#21CF7B"
                                             xmlns="http://www.w3.org/2000/svg" style={{
                                            position: 'absolute',
                                            top: "50%",
                                            left: '50%',
                                            transform: "translate(-50%, -50%)",
                                            opacity: '1'
                                        }}> >
                                            <path
                                                d="M3.57945 8.0801C3.37945 8.0801 3.18945 8.0001 3.04945 7.8601L0.219453 5.0301C-0.0705469 4.7401 -0.0705469 4.2601 0.219453 3.9701C0.509453 3.6801 0.989453 3.6801 1.27945 3.9701L3.57945 6.2701L8.71945 1.1301C9.00945 0.840098 9.48945 0.840098 9.77945 1.1301C10.0695 1.4201 10.0695 1.9001 9.77945 2.1901L4.10945 7.8601C3.96945 8.0001 3.77945 8.0801 3.57945 8.0801Z"
                                                fill="#21CF7B"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className='row mt-3 d-flex justify-content-center align-items-center '>
                                <div className="col-8 col-xl-10 col-lg-10 col-md-10 col-sm-10">
                                    <div style={{
                                        fontWeight: 'bold',
                                        color: '#232323',
                                        fontSize: '16px',
                                        marginBottom: '8px'
                                    }}>Connects Balance Notifications
                                    </div>
                                </div>
                                <div
                                    className="col-4 col-xl-2 col-lg-2 col-md-2 col-sm-2 p-0 d-flex justify-content-end ">
                                    <Switch
                                        isOn={valueConnect}
                                        handleToggle={toggleConnect}
                                        flagSwitch={'connect'}
                                        marginRight={20}
                                    />
                                </div>
                            </div>

                            <div className='row mt-3 d-flex align-items-center'
                                 style={{opacity: valueConnect ? '1' : '0.5'}}>
                                <div className="col-7 col-xl-7 col-lg-7 col-md-7 col-sm-7">
                                    <label htmlFor="inviteMember" className="input-with-icon"
                                           style={{display: 'block', width: '100%'}}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_23_2617)">
                                                <path
                                                    d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24Z"
                                                    fill="url(#paint0_linear_23_2617)"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M5.43104 11.8734C8.92928 10.3493 11.262 9.34452 12.4292 8.85905C15.7617 7.47294 16.4542 7.23216 16.9055 7.22421C17.0048 7.22246 17.2267 7.24706 17.3705 7.36372C17.4919 7.46223 17.5253 7.5953 17.5413 7.6887C17.5573 7.78209 17.5772 7.99485 17.5614 8.1611C17.3808 10.0586 16.5994 14.6633 16.2018 16.7885C16.0336 17.6877 15.7024 17.9892 15.3817 18.0188C14.6849 18.0829 14.1557 17.5582 13.4808 17.1158C12.4246 16.4235 11.8279 15.9925 10.8028 15.3169C9.61798 14.5362 10.386 14.107 11.0612 13.4058C11.2379 13.2222 14.3083 10.4295 14.3677 10.1761C14.3752 10.1444 14.3821 10.0263 14.3119 9.96397C14.2417 9.9016 14.1382 9.92293 14.0635 9.93989C13.9575 9.96393 12.2703 11.0791 9.00179 13.2855C8.52287 13.6143 8.08909 13.7745 7.70043 13.7662C7.27197 13.7569 6.44778 13.5239 5.83508 13.3247C5.08358 13.0804 4.4863 12.9513 4.53831 12.5364C4.5654 12.3203 4.86298 12.0993 5.43104 11.8734Z"
                                                      fill="white"/>
                                            </g>
                                            <defs>
                                                <linearGradient id="paint0_linear_23_2617" x1="12" y1="0" x2="12"
                                                                y2="23.822" gradientUnits="userSpaceOnUse">
                                                    <stop stop-color="#2AABEE"/>
                                                    <stop offset="1" stop-color="#229ED9"/>
                                                </linearGradient>
                                                <clipPath id="clip0_23_2617">
                                                    <rect width="24" height="24" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        <input
                                            type="text"
                                            id="inviteMember"
                                            className="form-control"
                                            placeholder="Telegram Channel"
                                            style={{width: 'calc(100%)', paddingLeft: '40px'}}
                                        />
                                    </label>
                                </div>
                                <div className="col-2 col-xl-2 col-lg-2 col-md-2 col-sm-2">
                                    <div style={{
                                        width: "24px",
                                        height: "25px",
                                        backgroundColor: "rgba(33, 207, 123, 0.25)",
                                        position: 'relative',
                                        borderRadius: '5px'
                                    }}>
                                        <svg width="14" height="14" viewBox="0 0 10 9" fill="#21CF7B"
                                             xmlns="http://www.w3.org/2000/svg" style={{
                                            position: 'absolute',
                                            top: "50%",
                                            left: '50%',
                                            transform: "translate(-50%, -50%)",
                                            opacity: '1'
                                        }}> >
                                            <path
                                                d="M3.57945 8.0801C3.37945 8.0801 3.18945 8.0001 3.04945 7.8601L0.219453 5.0301C-0.0705469 4.7401 -0.0705469 4.2601 0.219453 3.9701C0.509453 3.6801 0.989453 3.6801 1.27945 3.9701L3.57945 6.2701L8.71945 1.1301C9.00945 0.840098 9.48945 0.840098 9.77945 1.1301C10.0695 1.4201 10.0695 1.9001 9.77945 2.1901L4.10945 7.8601C3.96945 8.0001 3.77945 8.0801 3.57945 8.0801Z"
                                                fill="#21CF7B"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 d-flex align-items-center'
                                 style={{opacity: valueConnect ? '1' : '0.5'}}>
                                <div className="col-7 col-xl-7 col-lg-7 col-md-7 col-sm-7">
                                    <label htmlFor="inviteMember" className="input-with-icon"
                                           style={{display: 'block', width: '100%'}}>
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M12 0.25C18.4893 0.25 23.75 5.51065 23.75 12C23.75 18.4893 18.4893 23.75 12 23.75C5.51065 23.75 0.25 18.4893 0.25 12C0.25 5.51065 5.51065 0.25 12 0.25Z"
                                                fill="#FDFDFD" stroke="#E2E2E2" stroke-width="0.5"/>
                                            <path
                                                d="M8.53011 13.5863C8.53011 14.2838 7.96641 14.8475 7.26896 14.8475C6.57151 14.8475 6.00781 14.2838 6.00781 13.5863C6.00781 12.8889 6.57151 12.3252 7.26896 12.3252H8.53011V13.5863Z"
                                                fill="#E01E5A"/>
                                            <path
                                                d="M9.16406 13.5863C9.16406 12.8889 9.72776 12.3252 10.4252 12.3252C11.1227 12.3252 11.6864 12.8889 11.6864 13.5863V16.7392C11.6864 17.4367 11.1227 18.0004 10.4252 18.0004C9.72776 18.0004 9.16406 17.4367 9.16406 16.7392V13.5863Z"
                                                fill="#E01E5A"/>
                                            <path
                                                d="M10.4252 8.52229C9.72776 8.52229 9.16406 7.9586 9.16406 7.26115C9.16406 6.56369 9.72776 6 10.4252 6C11.1227 6 11.6864 6.56369 11.6864 7.26115V8.52229H10.4252Z"
                                                fill="#36C5F0"/>
                                            <path
                                                d="M10.4236 9.16211C11.121 9.16211 11.6847 9.7258 11.6847 10.4233C11.6847 11.1207 11.121 11.6844 10.4236 11.6844H7.26115C6.56369 11.6844 6 11.1207 6 10.4233C6 9.7258 6.56369 9.16211 7.26115 9.16211H10.4236Z"
                                                fill="#36C5F0"/>
                                            <path
                                                d="M15.4766 10.4233C15.4766 9.7258 16.0403 9.16211 16.7377 9.16211C17.4352 9.16211 17.9989 9.7258 17.9989 10.4233C17.9989 11.1207 17.4352 11.6844 16.7377 11.6844H15.4766V10.4233Z"
                                                fill="#2EB67D"/>
                                            <path
                                                d="M14.8465 10.4236C14.8465 11.121 14.2828 11.6847 13.5854 11.6847C12.8879 11.6847 12.3242 11.121 12.3242 10.4236V7.26115C12.3242 6.56369 12.8879 6 13.5854 6C14.2828 6 14.8465 6.56369 14.8465 7.26115V10.4236Z"
                                                fill="#2EB67D"/>
                                            <path
                                                d="M13.5854 15.4775C14.2828 15.4775 14.8465 16.0412 14.8465 16.7387C14.8465 17.4361 14.2828 17.9998 13.5854 17.9998C12.8879 17.9998 12.3242 17.4361 12.3242 16.7387V15.4775H13.5854Z"
                                                fill="#ECB22E"/>
                                            <path
                                                d="M13.5854 14.8475C12.8879 14.8475 12.3242 14.2838 12.3242 13.5863C12.3242 12.8889 12.8879 12.3252 13.5854 12.3252H16.7478C17.4452 12.3252 18.0089 12.8889 18.0089 13.5863C18.0089 14.2838 17.4452 14.8475 16.7478 14.8475H13.5854Z"
                                                fill="#ECB22E"/>
                                        </svg>
                                        <input
                                            type="text"
                                            id="inviteMember"
                                            className="form-control"
                                            placeholder="Slack Channel"
                                            style={{width: 'calc(100%)', paddingLeft: '40px'}}
                                        />
                                    </label>
                                </div>
                                <div className="col-2 col-xl-2 col-lg-2 col-md-2 col-sm-2">
                                    <div style={{
                                        width: "24px",
                                        height: "25px",
                                        backgroundColor: "rgba(33, 207, 123, 0.25)",
                                        position: 'relative',
                                        borderRadius: '5px'
                                    }}>
                                        <svg width="14" height="14" viewBox="0 0 10 9" fill="#21CF7B"
                                             xmlns="http://www.w3.org/2000/svg" style={{
                                            position: 'absolute',
                                            top: "50%",
                                            left: '50%',
                                            transform: "translate(-50%, -50%)",
                                            opacity: '1'
                                        }}> >
                                            <path
                                                d="M3.57945 8.0801C3.37945 8.0801 3.18945 8.0001 3.04945 7.8601L0.219453 5.0301C-0.0705469 4.7401 -0.0705469 4.2601 0.219453 3.9701C0.509453 3.6801 0.989453 3.6801 1.27945 3.9701L3.57945 6.2701L8.71945 1.1301C9.00945 0.840098 9.48945 0.840098 9.77945 1.1301C10.0695 1.4201 10.0695 1.9001 9.77945 2.1901L4.10945 7.8601C3.96945 8.0001 3.77945 8.0801 3.57945 8.0801Z"
                                                fill="#21CF7B"/>
                                        </svg>
                                    </div>
                                </div>
                            </div>

                            <div className="square">
                                <div className='row d-flex justify-content-center align-items-center mb-2 '>
                                    <div className="col-8 col-xl-9 col-lg-9 col-md-8 col-sm-8">
                                        <div style={{
                                            fontWeight: '400',
                                            color: '#232323',
                                            fontSize: '14px',
                                            marginBottom: '8px'
                                        }}>Low connects balance alert
                                        </div>
                                    </div>
                                    <div
                                        className="col-4 col-xl-3 col-lg-3 col-md-4 col-sm-4 p-0  d-flex justify-content-center ">
                                        <Switch
                                            isOn={valueConnectAlert}
                                            handleToggle={toggleConnectAlert}
                                            flagSwitch={'connect_alert'}
                                            marginRight={0}
                                        />
                                    </div>
                                </div>
                                <div className="square_line">

                                </div>
                                <div className='row  d-flex justify-content-center align-items-center  mt-2'>
                                    <div className="col-8 col-xl-9 col-lg-9 col-md-8 col-sm-8">
                                        <div style={{
                                            fontWeight: '400',
                                            color: '#232323',
                                            fontSize: '14px',
                                            marginBottom: '8px'
                                        }}>Auto top-up alert
                                        </div>
                                    </div>
                                    <div
                                        className="col-4 col-xl-3 col-lg-3 col-md-4 col-sm-4 p-0 d-flex justify-content-center ">
                                        <Switch
                                            isOn={valueTopUpAlert}
                                            handleToggle={toggleTopUpAlert}
                                            flagSwitch={'top_up_alert'}
                                            marginRight={0}
                                        />
                                    </div>
                                </div>
                            </div>


                            <div className='row mt-3  '>
                                <div className="col-8 col-xl-9 col-lg-9 col-md-9 col-sm-9">
                                    <div style={{
                                        fontWeight: 'bold',
                                        color: '#232323',
                                        fontSize: '16px',
                                        marginBottom: '8px'
                                    }}>Minimum Connects Balance
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-2 d-flex align-items-center'>
                                <div className="col-3 col-xl-2 col-lg-2 col-md-2 col-sm-3">
                                    <label htmlFor="inviteMember" className="input-with-icon"
                                           style={{display: 'block', width: '100%'}}>
                                        <input
                                            type="text"
                                            id="inviteMember"
                                            className="form-control"
                                            placeholder="Balance"
                                            style={{width: '100%'}}
                                        />
                                    </label>
                                </div>
                                <div className="col-1 col-xl-1 col-lg-1 col-md-1 col-sm-1">
                                    <div style={{
                                        width: "24px",
                                        height: "25px",
                                        backgroundColor: "rgba(33, 207, 123, 0.25)",
                                        position: 'relative',
                                        borderRadius: '5px'
                                    }}>
                                        <svg width="14" height="14" viewBox="0 0 10 9" fill="#21CF7B"
                                             xmlns="http://www.w3.org/2000/svg" style={{
                                            position: 'absolute',
                                            top: "50%",
                                            left: '50%',
                                            transform: "translate(-50%, -50%)",
                                            opacity: '1'
                                        }}> >
                                            <path
                                                d="M3.57945 8.0801C3.37945 8.0801 3.18945 8.0001 3.04945 7.8601L0.219453 5.0301C-0.0705469 4.7401 -0.0705469 4.2601 0.219453 3.9701C0.509453 3.6801 0.989453 3.6801 1.27945 3.9701L3.57945 6.2701L8.71945 1.1301C9.00945 0.840098 9.48945 0.840098 9.77945 1.1301C10.0695 1.4201 10.0695 1.9001 9.77945 2.1901L4.10945 7.8601C3.96945 8.0001 3.77945 8.0801 3.57945 8.0801Z"
                                                fill="#21CF7B"/>
                                        </svg>
                                    </div>
                                </div>
                                <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 p-0"
                                     style={{fontSize: "14px", color: '#49494B', left: '40px'}}>
                                    Saved automatically
                                </div>
                            </div>
                        </div>
                    )}
                    {activeLink === 'Billing' && (
                        <div>
                            <div className='mt-4 p-0 '
                                 style={{fontWeight: 'bold', color: 'black', fontSize: '20px'}}>Billing Settings
                            </div>
                            <div className='mt-1 p-0 '
                                 style={{color: 'black', fontSize: '12px'}}>Your account billing dashboard
                            </div>
                            <div className='mt-4 p-0 '
                                 style={{fontWeight: 'bold', color: 'black', fontSize: '16px'}}>Platform Usage
                            </div>
                            <div className="square-billing">
                                <div className='row d-flex justify-content-center align-items-center mb-2 '>
                                    <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                        <div style={{
                                            fontWeight: '400',
                                            color: '#49494B',
                                            fontSize: '14px',
                                        }}>Subscription:
                                        </div>
                                    </div>
                                    <div
                                        className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 p-0 ">
                                        <div
                                            className='row d-flex justify-content-end align-content-end align-items-center'>
                                            <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 p-0 " style={{
                                                fontWeight: 'bold',
                                                color: '#41414E',
                                                fontSize: '14px',
                                            }}>
                                                Eva 399
                                            </div>
                                            <div className="col-8 col-xl-8 col-lg-8 col-md-8 col-sm-8  ">
                                                <button
                                                    style={{
                                                        width: '89px',
                                                        height: '26px',
                                                        borderRadius: '30px',
                                                        boxShadow: '0px 0px 0px 0.5px #0000000D, 0px 0.5px 2.5px 0px #0000004D',
                                                        border: 'none', // Optionally remove border
                                                        outline: 'none', // Optionally remove outline
                                                        cursor: 'pointer', // Optionally change cursor on hover
                                                        background: '#FFFFFF',
                                                        fontSize: '12px',
                                                        padding: '3px 7px 3px 7px'
                                                    }}
                                                >
                                                    Details
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="square-billing-line">

                                </div>
                                <div className='row  d-flex justify-content-center align-items-center  mt-2'>
                                    <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6  ">
                                        <div style={{
                                            fontWeight: '400',
                                            color: '#49494B',
                                            fontSize: '14px',
                                        }}>Credit Balance:
                                        </div>
                                    </div>
                                    <div
                                        className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 p-0 ">
                                        <div className='row'>
                                            <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 p-0 " style={{
                                                fontWeight: 'bold',
                                                color: '#41414E',
                                                fontSize: '14px',
                                            }}>
                                                $10.32
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    )}
                    {activeLink === 'Members' && (
                        <div>
                            <div className='mt-4 p-0 '
                                 style={{fontWeight: 'bold', color: 'black', fontSize: '20px'}}>Members Settings
                            </div>
                            <div className='mt-1 p-0 '
                                 style={{color: 'black', fontSize: '12px'}}>Your account billing dashboard
                            </div>
                            <div className='mt-4 p-0 '
                                 style={{fontWeight: 'bold', color: 'black', fontSize: '16px'}}>Platform Usage
                            </div>
                            <div className='row mt-4'>
                                <div className="col-12 ">
                                    <span style={{fontWeight: 'bold', color: 'black', fontSize: '16px'}}> Invite member to team</span>
                                    <div className="row p-0 mt-2">
                                        <div className="col-6 col-xl-7 col-lg-7 col-md-7 col-sm-6">
                                            <label htmlFor="inviteMember" className="input-with-icon"
                                                   style={{display: 'block', width: '100%'}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"
                                                     style={{color: !isValid ? 'initial' : 'red'}}
                                                     fill="currentColor" className="bi bi-envelope"
                                                     viewBox="0 0 16 16">
                                                    <path
                                                        d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1zm13 2.383-4.708 2.825L15 11.105zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741M1 11.105l4.708-2.897L1 5.383z"/>
                                                </svg>
                                                <input
                                                    style={{
                                                        width: 'calc(100% - 30px)',
                                                        paddingLeft: '30px',
                                                        fontSize: '12px',
                                                        height: '40px',
                                                        color: !isValid ? 'initial' : 'red',
                                                        borderColor: !isValid ? 'initial' : 'red'
                                                    }}
                                                    type="text"
                                                    id="inviteMember"
                                                    className="form-control"
                                                    placeholder="Email address"
                                                    value={email}
                                                    onChange={handleChange}
                                                    required
                                                />
                                            </label>
                                            {isValid && <div style={{color: "red"}}>Please enter email address.</div>}
                                        </div>
                                        <div className="col-3 col-xl-2 col-lg-2 col-md-2 col-sm-3 p-0">
                                            <div
                                                className={`custom-dropdown p-0 ${isDropdownOpen ? 'open active' : ''}`}
                                                onClick={toggleDropdown} style={{width: '100px'}}>
                                                <div
                                                    className="container d-flex justify-content-between align-items-center p-0">
                                                    <span className="selected-option">{selectedOrdersStatus}</span>
                                                    <span className="custom-arrow-down">
                                                             {isDropdownOpen ? (
                                                                 <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                      height="16" fill="currentColor"
                                                                      className="bi bi-chevron-up" viewBox="0 0 16 16">
                                                                     <path fillRule="evenodd"
                                                                           d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708z"/>
                                                                 </svg>
                                                             ) : (
                                                                 <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                      height="16" fill="currentColor"
                                                                      className="bi bi-chevron-down"
                                                                      viewBox="0 0 16 16">
                                                                     <path fillRule="evenodd"
                                                                           d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                                                                 </svg>
                                                             )}
                                                        </span>
                                                </div>
                                                <ul className="dropdown-menu shadow-effect m-0"
                                                    aria-labelledby="dropdownMenuButton2" style={{width: '158px'}}>
                                                    {order_status.map((orderStatus) => (
                                                        <li key={orderStatus}
                                                            onClick={() => handleStatusSelection(orderStatus)}>
                                                            <a className={`dropdown-item d-flex align-items-center justify-content-between ${selectedOrdersStatus === orderStatus ? 'active' : ''}`}>
                                                                <span>{orderStatus}</span>
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        <div
                                            className="col-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 row justify-content-end p-0">
                                            <button className="btn btn-primary " onClick={handleUserInvite} style={{
                                                width: '100px',
                                                fontSize: '12px',
                                                height: '38px'
                                            }}>Invite
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-2">
                                    <div className='col-11 col-xl-11 col-lg-11 col-md-11 col-sm-11 mt-2 mb-2'
                                         style={{color: '#343C6A', fontSize: '18px', fontWeight: '500'}}>
                                        Members
                                    </div>
                                    <div className='col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 '>
                                        <table className="table">
                                            <thead>
                                            <tr className="table">
                                                {columns.map((column, index) => (
                                                    <th className='pt-2 pb-2 p-0' style={{
                                                        color: '#696969',
                                                        fontSize: '14px',
                                                        fontWeight: '500'
                                                    }} key={index}>
                                                        {column}
                                                    </th>

                                                ))}
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {renderTableData()}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
export default Setting;
