import React, {useEffect, useState} from 'react';
import CustomAreaChart from "./area-chart/CustomAreaChart";
import './Dashboard.css'
import {Outlet} from "react-router-dom";
import Pagination from "./pagination/Pagination";

const generateRandomUsers = () => {
    // Генеруємо простий список користувачів для прикладу
    const users = [];
    for (let i = 1; i <= 20; i++) {
        users.push({
            id: i,
            name: `User ${i}`,
            proposals: Math.floor(Math.random() * 30) + 20,
            replies: Math.floor(Math.random() * 30) + 20,
            avg_time: '00:14:25',
            lrr: '3,56%',
            email: `user${i}@example.com`,
        });
    }
    return users;
};

const ProposalSendingStats = ({data}) => {

    const columns = ['Name', 'Proposals', 'Replies', 'AVG Time', 'LRR'];
    const users = generateRandomUsers();
    const itemsPerPage = 12;
    const totalPages = Math.ceil(users.length / itemsPerPage);

    const [loading, setLoading] = useState(true);
    const [activeButton, setActiveButton] = useState('Manager');
    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 1000);
    }, []);
    // Функція для виведення поточної сторінки
    const renderTableData = () => {
        const start = (currentPage - 1) * itemsPerPage;
        const end = start + itemsPerPage;
        return users.slice(start, end).map((item, index) => (
            <tr key={index}>
                <td className="pt-3 pb-3 p-0">
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor"
                             className="bi bi-person-circle" viewBox="0 0 16 16">
                            <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0"/>
                            <path fill-rule="evenodd"
                                  d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8m8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1"/>
                        </svg>
                        <span style={{
                            marginLeft: '12px',
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#232323'
                        }}>{item.name}</span>
                    </div>
                </td>
                <td className="pt-3 pb-3 p-0" style={{fontWeight: '500', fontSize: '14px', color: '#232323'}}>
                    {item.proposals}
                </td>
                <td className="pt-3 pb-3 p-0"
                    style={{fontWeight: '500', fontSize: '14px', color: '#232323'}}>{item.replies}</td>
                <td className="pt-3 pb-3 p-0"
                    style={{fontWeight: '500', fontSize: '14px', color: '#232323'}}>{item.avg_time}</td>
                <td className="pt-3 pb-3 p-0"
                    style={{fontWeight: '500', fontSize: '14px', color: '#232323'}}>{item.lrr}</td>
            </tr>
        ));
    };

    const handlePreviousTest = (value) => {
        setCurrentPage(value);
    };
    const [dataChar, setDataChar] = useState([
        {name: '22', uv: 400, pv: 2400, amt: 2400},
        {name: '23', uv: 300, pv: 1398, amt: 2210},
        {name: '24', uv: 200, pv: 9800, amt: 2290},
        {name: '25', uv: 270, pv: 3908, amt: 2000},
        {name: '26', uv: 180, pv: 4800, amt: 2181},
        {name: '27', uv: 230, pv: 3800, amt: 2500},
        {name: '28', uv: 340, pv: 4300, amt: 2100},
    ]);
    const [dataChar1, setDataChar1] = useState([
        {name: '22', uv: 545, pv: 2400, amt: 2400},
        {name: '23', uv: 432, pv: 1398, amt: 2210},
        {name: '24', uv: 244, pv: 9800, amt: 2290},
        {name: '25', uv: 56, pv: 3908, amt: 2000},
        {name: '26', uv: 435, pv: 4800, amt: 2181},
        {name: '27', uv: 234, pv: 3800, amt: 2500},
        {name: '28', uv: 224, pv: 4300, amt: 2100},
    ]);
    const [dataChar2, setDataChar2] = useState([
        {name: '22', uv: 2400, pv: 2400, amt: 2400},
        {name: '23', uv: 2210, pv: 1398, amt: 2210},
        {name: '24', uv: 2290, pv: 9800, amt: 2290},
        {name: '25', uv: 2000, pv: 3908, amt: 2000},
        {name: '26', uv: 2181, pv: 4800, amt: 2181},
        {name: '27', uv: 2500, pv: 3800, amt: 2500},
        {name: '28', uv: 2100, pv: 4300, amt: 2100},
    ]);
    const handleButtonClick = (e, buttonName) => {
        setActiveButton(buttonName);
        if (buttonName === 'Manager') {
            setDataChar([
                {name: '22', uv: 432, pv: 2400, amt: 200},
                {name: '23', uv: 148, pv: 1398, amt: 210},
                {name: '24', uv: 900, pv: 9800, amt: 229},
                {name: '25', uv: 398, pv: 3908, amt: 450},
                {name: '26', uv: 400, pv: 4800, amt: 181},
                {name: '27', uv: 434, pv: 3800, amt: 500},
                {name: '28', uv: 344, pv: 4300, amt: 100},
            ])
            setDataChar1([
                {name: '22', uv: 423, pv: 2400, amt: 2400},
                {name: '23', uv: 1398, pv: 1398, amt: 2210},
                {name: '24', uv: 343, pv: 9800, amt: 2290},
                {name: '25', uv: 123, pv: 3908, amt: 2000},
                {name: '26', uv: 4544, pv: 4800, amt: 2181},
                {name: '27', uv: 543, pv: 3800, amt: 2500},
                {name: '28', uv: 42, pv: 4300, amt: 2100},
            ])
            setDataChar2(
                [
                    {name: '22', uv: 2400, pv: 2400, amt: 2400},
                    {name: '23', uv: 1398, pv: 1398, amt: 2210},
                    {name: '24', uv: 9800, pv: 9800, amt: 2290},
                    {name: '25', uv: 3908, pv: 3908, amt: 2000},
                    {name: '26', uv: 4800, pv: 4800, amt: 2181},
                    {name: '27', uv: 3800, pv: 3800, amt: 2500},
                    {name: '28', uv: 4300, pv: 4300, amt: 2100},
                ])
        }
        if (buttonName === 'Freelancer') {
            setDataChar([
                {name: '22', uv: 24, pv: 432, amt: 200},
                {name: '23', uv: 553, pv: 148, amt: 556},
                {name: '24', uv: 23, pv: 900, amt: 444},
                {name: '25', uv: 454, pv: 398, amt: 545},
                {name: '26', uv: 343, pv: 400, amt: 324},
                {name: '27', uv: 128, pv: 380, amt: 323},
                {name: '28', uv: 315, pv: 434, amt: 43},
            ])
            setDataChar1([
                {name: '22', uv: 432, pv: 2400, amt: 200},
                {name: '23', uv: 148, pv: 1398, amt: 210},
                {name: '24', uv: 900, pv: 9800, amt: 229},
                {name: '25', uv: 398, pv: 3908, amt: 450},
                {name: '26', uv: 400, pv: 4800, amt: 181},
                {name: '27', uv: 434, pv: 3800, amt: 500},
                {name: '28', uv: 344, pv: 4300, amt: 100},
            ])
            setDataChar2([
                {name: '22', uv: 423, pv: 2400, amt: 423},
                {name: '23', uv: 1398, pv: 1398, amt: 880},
                {name: '24', uv: 343, pv: 9800, amt: 123},
                {name: '25', uv: 123, pv: 3908, amt: 543},
                {name: '26', uv: 544, pv: 4800, amt: 787},
                {name: '27', uv: 543, pv: 3800, amt: 346},
                {name: '28', uv: 42, pv: 4300, amt: 42},
            ])
        }
        if (buttonName === 'Scanner') {
            setDataChar([
                {name: '22', uv: 233, pv: 2400, amt: 2400},
                {name: '23', uv: 430, pv: 1398, amt: 2210},
                {name: '24', uv: 234, pv: 9800, amt: 2290},
                {name: '25', uv: 343, pv: 3908, amt: 2000},
                {name: '26', uv: 124, pv: 4800, amt: 2181},
                {name: '27', uv: 50, pv: 3800, amt: 2500},
                {name: '28', uv: 456, pv: 4300, amt: 2100},
            ])
            setDataChar1([
                {name: '22', uv: 2400, pv: 2400, amt: 2400},
                {name: '23', uv: 1398, pv: 1398, amt: 2210},
                {name: '24', uv: 9800, pv: 9800, amt: 2290},
                {name: '25', uv: 3908, pv: 3908, amt: 2000},
                {name: '26', uv: 4800, pv: 4800, amt: 2181},
                {name: '27', uv: 3800, pv: 3800, amt: 2500},
                {name: '28', uv: 4300, pv: 4300, amt: 2100},
            ])
            setDataChar2([
                {name: '22', uv: 423, pv: 2400, amt: 2400},
                {name: '23', uv: 1398, pv: 1398, amt: 2210},
                {name: '24', uv: 343, pv: 9800, amt: 2290},
                {name: '25', uv: 123, pv: 3908, amt: 2000},
                {name: '26', uv: 4544, pv: 4800, amt: 2181},
                {name: '27', uv: 543, pv: 3800, amt: 2500},
                {name: '28', uv: 42, pv: 4300, amt: 2100},
            ])
        }

    };


    return (
        <div>
            {loading ?
                (<div className="d-flex justify-content-center align-items-center mt-5">
                    <div className="spinner-border" role="status">
                    </div>
                </div>)
                :
                (<div className='row mt-5'>
                    <Outlet/>
                    <div className='col-12 col-xl-8 col-lg-7 col-md-12 col-sm-12'>
                        <div className="card mb-3" style={{height: 'auto', borderRadius: '25px', border: "none"}}>
                            <div className="card-body">
                                <div className='row mb-3 mt-2 d-flex justify-content-between align-items-center'>
                                    <div className="col-12 col-xl-5 col-lg-4 col-md-5 col-sm-6 "
                                         style={{fontWeight: 'bold', fontSize: '18px', color: '#343C6A'}}>
                                        Proposal Sending Stats
                                    </div>
                                    <div
                                        className="col-10 col-xl-5 col-lg-7 col-md-6 col-sm-10 d-flex justify-content-between align-items-center p-0  me-2 col-md-6-custom"
                                        style={{
                                            height: "auto",
                                            backgroundColor: '#FFFFFF',
                                            boxShadow: '0px 4px 13px 0px #99B9EA26',
                                            borderRadius: '27px'
                                        }}>
                                        <button
                                            className={`btn text-center ${activeButton === 'Manager' ? 'btn-primary' : 'btn-white'}`}
                                            onClick={(e) => handleButtonClick(e, 'Manager')}
                                            style={{
                                                padding: '3px 20px',
                                                borderRadius: '18px',
                                            }}
                                        >
                                            Manager
                                        </button>
                                        <button
                                            className={`btn text-center ${activeButton === 'Freelancer' ? 'btn-primary' : 'btn-white'}`}
                                            onClick={(e) => handleButtonClick(e, 'Freelancer')}
                                            style={{
                                                padding: '3px 20px',
                                                borderRadius: '18px',
                                            }}
                                        >
                                            Freelancer
                                        </button>
                                        <button
                                            className={`btn text-center ${activeButton === 'Scanner' ? 'btn-primary' : 'btn-white'}`}
                                            onClick={(e) => handleButtonClick(e, 'Scanner')}

                                            style={{
                                                padding: '3px 20px',
                                                borderRadius: '18px',
                                            }}
                                        >
                                            Scanner
                                        </button>
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table">
                                        <thead>
                                        <tr className="table">
                                            {columns.map((column, index) => (
                                                <th className="pt-3 pb-3 p-0"
                                                    style={{fontWeight: 'bold', fontSize: '14px', color: '#696969'}}
                                                    key={index}>
                                                    {column}
                                                </th>
                                            ))}
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {renderTableData()}
                                        </tbody>
                                        <tfoot>
                                        <tr style={{border: 'none'}}>
                                            <td className="pt-3 pb-1 p-0" style={{
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                color: '#232323',
                                                border: 'none'
                                            }}>Total
                                            </td>
                                            <td className="pt-3 pb-1 p-0" style={{
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                color: '#232323',
                                                border: 'none'
                                            }}>432
                                            </td>
                                            <td className="pt-3 pb-1 p-0" style={{
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                color: '#232323',
                                                border: 'none'
                                            }}>34
                                            </td>
                                            <td className="pt-3 pb-1 p-0" style={{
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                color: '#232323',
                                                border: 'none'
                                            }}>13:51:30
                                            </td>
                                            <td className="pt-3 pb-1 p-0" style={{
                                                fontWeight: 'bold',
                                                fontSize: '16px',
                                                color: '#232323',
                                                border: 'none'
                                            }}>12.67%
                                            </td>
                                        </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                            <Pagination totalPages={23} handlePreviousTest={handlePreviousTest}/>
                        </div>
                    </div>
                    <div className="col-12 col-xl-4 col-lg-5 col-md-12 col-sm-12">
                        <div className="card mb-3" style={{height: 'auto', borderRadius: '25px', border: "none"}}>
                            <div className="card-body">
                                <div className='row justify-content-between'>
                                    <div className="col-8 col-xl-5 col-lg-5 col-md-6 col-sm-6 text-muted">
                                        <div style={{color: '#343C6A'}}>Proposals</div>
                                        <div style={{color: '#718EBF', fontSize: '13px'}}>
                                            Reply rate 3.45%
                                        </div>
                                    </div>
                                    <div
                                        className="col-4 col-xl-6 col-lg-5 col-md-6 col-sm-6 text-end display-5 mb-4"
                                        style={{color: "#4273F4", fontWeight: 600, fontSize: '32px'}}
                                    >
                                        350
                                    </div>
                                </div>
                                <div style={{width: '100%', height: '200px', alignItems: 'center'}}>
                                    <CustomAreaChart data={dataChar} dataKeyX={'name'} dataKeyY={'uv'}
                                                     gradientColor={"#8884d8"}
                                                     strokeColor={"#4273F4"} keyColor={'color-dataChar'}
                                                     keyColorUrl={"url(#color-dataChar)"}/>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3" style={{height: 'auto', borderRadius: '25px', border: "none"}}>
                            <div className="card-body">
                                <div className='row justify-content-between'>
                                    <div className="col-8 col-xl-5 col-lg-5 col-md-6 col-sm-6 text-muted">
                                        <div style={{color: '#343C6A'}}>Invitations</div>
                                        <div style={{color: '#718EBF', fontSize: '13px'}}>
                                            Reply rate 3.45%
                                        </div>
                                    </div>
                                    <div
                                        className="col-4 col-xl-6 col-lg-5 col-md-6 col-sm-6 text-end display-5 mb-4"
                                        style={{
                                            color: "#21CF7B"
                                            , fontWeight: 600, fontSize: '32px'
                                        }}>
                                        1 350
                                    </div>
                                </div>
                                <div style={{width: '100%', height: '200px'}}>
                                    <CustomAreaChart data={dataChar1} dataKeyX={'name'} dataKeyY={'amt'}
                                                     gradientColor={"#21CF7B"} strokeColor={"#21CF7B"}
                                                     keyColor={'color-dataChar1'}
                                                     keyColorUrl={"url(#color-dataChar1)"}/>
                                </div>
                            </div>
                        </div>
                        <div className="card mb-3" style={{height: 'auto', borderRadius: '25px', border: "none"}}>
                            <div className="card-body">
                                <div className='row justify-content-between'>
                                    <div className="col-8 col-xl-7 col-lg-5 col-md-6 col-sm-6 text-muted">
                                        <div style={{color: '#343C6A'}}>Profile Views</div>
                                        <div style={{color: '#718EBF', fontSize: '13px'}}>
                                            Only exclusive agency members
                                        </div>
                                    </div>
                                    <div
                                        className="col-4 col-xl-5 col-lg-5 col-md-6 col-sm-6 text-end display-5 mb-4"
                                        style={{
                                            color: "#FFAE55"
                                            , fontWeight: 600, fontSize: '32px'
                                        }}>
                                        13 350
                                    </div>
                                </div>
                                <div style={{width: '100%', height: '198px'}}>
                                    <CustomAreaChart data={dataChar2} dataKeyX={'name'} dataKeyY={'pv'}
                                                     gradientColor={"#FFAE5580"} strokeColor={"#FFAE55"}
                                                     keyColor={'color-dataChar2'}
                                                     keyColorUrl={"url(#color-dataChar2)"}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>)
            }

        </div>
    );
};

export default ProposalSendingStats;
