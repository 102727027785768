import {useLocation} from "react-router-dom";
import React, {useState} from "react";
const SUPPORT_TOKEN = process.env.REACT_APP_SUPPORT_TOKEN;
function CodeInformation() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const authorizationCode = searchParams.get('code');
    const [responseData, setResponseData] = useState(null);
    const [userEmail, setUserEmail] = useState("");
    const [error, setError] = useState(null);
    const handleInputChange = (event) => {
        setUserEmail(event.target.value);
    };
    const handleAuthorize = async () => {
        try {
            const response = await fetch(`https://upraider.com/api/v1/auth/token?code=${authorizationCode}&email=${userEmail}`, {
                method: 'GET',
                headers: {
                    'SupportToken': SUPPORT_TOKEN
                }
            });

            const data = await response.json();
            setResponseData(data);
        } catch (error) {
            console.log('[ERROR](api/v1/auth/token) ::', error)
            setError(error.message);
        }
    };


    return (
        <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "70vh"}}>
            <div>
                <div style={{
                    backgroundColor: '#f6f6f6',
                    fontSize: '20px',
                    marginBottom: '10px'
                }}>Authorization Code: <span style={{color: "red"}}>{authorizationCode}</span></div>
                <div style={{
                    backgroundColor: '#f6f6f6',
                    fontSize: '20px',
                    marginBottom: '10px'
                }}>Email user
                </div>
                <input type="text" value={userEmail} onChange={handleInputChange} style={{
                    backgroundColor: '#f6f6f6',
                    border: "3px solid #343C6A",
                }}/>
                <button onClick={handleAuthorize} style={{
                    backgroundColor: '#343C6A',
                    border: "1px",
                    borderRadius: '10px',
                    color: 'white',
                    cursor: "pointer",
                    marginLeft: '10px'
                }}>
                    Submit
                </button>
                {error && ( <div style={{
                    backgroundColor: '#f6f6f6',
                    fontSize: '20px',
                    marginTop: '10px'
                }}><p >Error: {error}</p></div>)}
                {responseData && (
                    <div style={{
                        backgroundColor: '#f6f6f6',
                        fontSize: '20px',
                        marginTop: '10px'
                    }}>
                        <p>Response Data: {JSON.stringify(responseData)}</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CodeInformation;
