import React, {useState} from "react";

const PASSWORD = process.env.REACT_APP_PASSWORD;

function redirectToUpwork() {
    window.location.href = 'https://upwork.com/ab/account-security/oauth2/authorize?response_type=code&client_id=20242b30362c24a14fa274a2a406187a&redirect_uri=https://upraider.com/code';

}

function AuthorizeUpwork() {
    const [userPassword, setUserPassword] = useState("");
    const [isAuthorized, setIsAuthorized] = useState(false);

    const handleInputChange = (event) => {
        setUserPassword(event.target.value);
    };

    const handleAuthorize = () => {
        if (userPassword === PASSWORD) {
            setIsAuthorized(true);
        } else {
            alert("Incorrect password. Please try again.");
        }
    };

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "70vh" }}>
            {isAuthorized ? (
                <button
                    style={{
                        backgroundColor:'#343C6A',
                        border: "1px",
                        borderRadius:'10px',
                        color:'white',
                        cursor: "pointer",
                        marginLeft:'10px'
                    }}
                    onClick={redirectToUpwork}>
                    Authorize Upwork
                </button>
            ) : (
                <div>
                    <div style={{
                        backgroundColor:'#f6f6f6',
                        fontSize:'20px',
                        marginBottom:'10px'
                    }}>System Password</div>
                    <input type="password" value={userPassword} onChange={handleInputChange} style={{
                        backgroundColor:'#f6f6f6',
                        border: "3px solid #343C6A",
                    }}/>
                    <button onClick={handleAuthorize} style={{
                        backgroundColor:'#343C6A',
                        border: "1px",
                        borderRadius:'10px',
                        color:'white',
                        cursor: "pointer",
                        marginLeft:'10px'
                    }}>
                        Check
                    </button>
                </div>
            )}
        </div>
    );
}

export default AuthorizeUpwork;


