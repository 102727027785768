import React from 'react';

const CustomButton = ({type, label}) => {
    let buttonStyle = {
        height: '27px',
        padding: '6px 16px 6px 16px',
        borderRadius: '30px',
        border: '1px solid',
        fontSize: '12px',
        cursor: 'pointer',
        outline: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        fontWeight: 500
    };

    switch (type) {
        case 'Sent':
            buttonStyle.background = '#4273F4';
            buttonStyle.color = '#FFFFFF';
            buttonStyle.width = '59px'
            buttonStyle.border = '1px solid #3763D6'
            break;
        case 'Pending':
            buttonStyle.background = '#FFAE55';
            buttonStyle.color = '#FFFFFF';
            buttonStyle.width = '79px'
            buttonStyle.border = '1px solid #F69933'
            break;
        case 'Rejection':
            buttonStyle.background = '#FE5C73';
            buttonStyle.color = '#FFFFFF';
            buttonStyle.width = '86px'
            buttonStyle.border = '1px solid #E13B53'
            break;
        default:
            break;
    }

    return (
        <div style={buttonStyle}>
            {label}
        </div>
    );
};

export default CustomButton;
